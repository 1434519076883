import Vue from 'vue';
import _ from 'lodash';
import moment from 'moment';

import BaseModel from './BaseModel';
import FileModel from './FileModel';
import UserModel from './UserModel';
import TaskTimeModel from './TaskTimeModel';
import { PropertyLocationModel } from './LocationModels';
import { titleCase } from '../util';

class ReservationModel extends BaseModel {
  init(data) {
    this.id = data.id || 0;
    this.start_time = data.start_time ? new Date(data.start_time) : null;
    this.end_time = data.end_time ? new Date(data.end_time) : null;
    this.location = data.location ? PropertyLocationModel.factory(data.location) : null;
    this.request = data.request ? new ReservationRequestModel(data.request) : null;
    this.user = data.user ? new UserModel(data.user) : null;
    this.status = data.status || 'approved';

    if ('available' in data) {
      this.available = data.available;
    }
  }

  get duration() {
    const time_in_seconds = moment(this.end_time).diff(moment(this.start_time), 'second');
    const model = new TaskTimeModel({
      start_time: this.start_time,
      end_time: this.end_time,
      time_in_seconds,
    });
    return model.display_value;
  }

  get requesterName() {
    return this.user?.fullName || '';
  }
}

class ReservationRequestModel extends BaseModel {
  static ReservationRequestStatus = {
    PENDING_REVIEW: 'pending_review',
    CANCELED: 'canceled',
    REVIEWED: 'reviewed',
  };

  init(data) {
    this.id = data.id || 0;
    this.name = data.name || '';
    this.description = data.description || '';
    this.created = data.created ? new Date(data.created) : null;
    this.status = data.status || '';
    this.reviewed_status = data.reviewed_status || '';
    this.user = data.user ? new UserModel(data.user) : null;
    this.reviewers = (data.reviewers || []).map(a => new UserModel(a));
    this.reviewed_by = data.reviewed_by ? new UserModel(data.reviewed_by) : null;
    this.attachments = (data.attachments || []).map(f => new FileModel(f));
    this.reservations = (data.reservations || []).map(r => new ReservationModel(r));
  }

  get requesterName() {
    return this.user?.fullName || '';
  }

  get reviewerName() {
    return this.reviewed_by?.fullName || '';
  }

  get statusDisplay() {
    return titleCase(this.status);
  }

  get reviewedStatusDisplay() {
    if (this.reviewed_status === 'partial') {
      return 'Approved (Partial)';
    }
    return titleCase(this.reviewed_status || Vue.prototype.$globals.defaultDisplayValue);
  }

  get canCancel() {
    return this.status === 'pending_review' && this.user?.id === Vue.prototype.$globals.user()?.id;
  }

  // a de-duplicated list of start times - mostly to see if we have multiple
  get reservationStartTimes() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.start_time?.toString() === b.start_time?.toString())).map(r => r.start_time);
  }

  get hasMultipleStartTimes() {
    return this.reservationStartTimes.length > 1;
  }

  get reservationEndTimes() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.end_time?.toString() === b.end_time?.toString())).map(r => r.end_time);
  }

  get hasMultipleEndTimes() {
    return this.reservationEndTimes.length > 1;
  }

  get reservationDurations() {
    if (!this.reservations.length) return [];
    return _.zip(this.reservationStartTimes, this.reservationEndTimes).map((r) => {
      const start_time = r[0];
      const end_time = r[1];
      const time_in_seconds = moment(end_time).diff(moment(start_time), 'second');
      const model = new TaskTimeModel({
        start_time,
        end_time,
        time_in_seconds,
      });
      return model.display_value;
    });
  }

  get hasMultipleDurations() {
    return this.reservationDurations.length > 1;
  }

  get reservationLocations() {
    if (!this.reservations.length) return [];

    return _.uniqWith(this.reservations, (a, b) => (a.location?.id === b.location?.id)).map(r => r.location);
  }

  get hasMultipleLocations() {
    return this.reservationLocations.length > 1;
  }

  get locationName() {
    return !this.hasMultipleLocations
      ? this.reservationLocations[0].fullAncestryPath
      : 'Multiple';
  }
}

export {
  ReservationModel,
  ReservationRequestModel,
};
